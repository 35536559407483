<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>账号安全策略</b></span>
          </div>
        </div>
        <div class="department-content auto" id="no-margin-form">
          <div class="box auto">
            <div class="box-title auto">
              <div class="fl">密码校验管理</div>
            </div>
            <div class="box-con auto m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="{span: 4}"
                :wrapper-col="{span: 15}"
                labelAlign="left"
                style="max-width: 800px;"
              >
                <a-form-model-item label="密码最小长度" prop="password_length_min">
                  <a-input type="number" v-model="form.password_length_min" size="small" style="width:68px;margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码最大长度" prop="password_length_max">
                  <a-input type="number" v-model="form.password_length_max" size="small" style="width:68px;margin-left:-34px;"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码规则" prop="rule">
                  <div style="margin-left:-60px;">
                    <!-- <a-checkbox-group v-model="form.rule" :options="ruleOptions" /> -->
                    <a-checkbox v-model="form.password_regex_numerial">数字</a-checkbox>
                    <a-checkbox v-model="form.password_regex_lowercase">小写字母</a-checkbox>
                    <a-checkbox v-model="form.password_regex_uppercase">大写字母</a-checkbox>
                    <a-checkbox v-model="form.password_regex_specificsymbol">符号</a-checkbox>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitPasswordverify">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
          <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">账号安全参数</div>
            </div>
            <div class="box-con auto no-bor m-t-20">
              <a-form-model
                ref="ruleForm"
                :model="form2"
                :rules="rules2"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                labelAlign="left"
                style="width: 800px;"
              >
                <a-form-model-item label="密码提醒修改周期（天）" prop="password_modify_cycle">
                  <a-input type="number" v-model="form2.password_modify_cycle" size="small" style="width:68px;"></a-input>
                  <span>（提醒用户修改密码的周期，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="密码强制修改周期（天）" prop="password_forcereset_cycle">
                  <a-input type="number" v-model="form2.password_forcereset_cycle" size="small" style="width:68px;"></a-input>
                  <span>（强制用户修改密码的周期，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="账号闲置锁定周期（天）" prop="account_lock_unuse">
                  <a-input type="number" v-model="form2.account_lock_unuse" size="small" style="width:68px;"></a-input>
                  <span>（超过该时长未登录的账号将转为闲置锁定状态，设置为 0 禁用此功能）</span>
                </a-form-model-item>
                <a-form-model-item label="">
                  <a-button type="primary" size="small" style="margin-top:15px;" @click="submitAccountsafety">保存</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      ruleOptions: [
        { label: '数字', value: 'number' },
        { label: '小写字母', value: 'lower' },
        { label: '大写字母', value: 'up' },
        { label: '符号', value: 'fh' },
      ],
      form: {
        password_length_min: '',
        password_length_max: '',
        password_regex_numerial: false,
        password_regex_lowercase: false,
        password_regex_uppercase: false,
        password_regex_specificsymbol: false
      },
      rules: {
        // min: [
        //   { required: true, message: '请输入最小长度', trigger: 'blur' }
        // ]
      },
      form2: {
        password_modify_cycle: '',
        password_forcereset_cycle: '',
        account_lock_unuse: ''
      },
      rules2: {
        // min: [
        //   { required: true, message: '', trigger: 'blur' },
        //   { min: 3, max: 5, message: '', trigger: 'blur' },
        // ]
      }
    }
  },
  methods: {
    async getPasswordverify () {
      this.loading = true
      let res = await System.getPasswordverify()
      if (!res['code']) {
        this.form = {
          password_length_min: res['data']['password_length_min'],
          password_length_max: res['data']['password_length_max'],
          password_regex_numerial: res['data']['password_regex_numerial'] ? true : false,
          password_regex_lowercase: res['data']['password_regex_lowercase'] ? true : false,
          password_regex_uppercase: res['data']['password_regex_uppercase'] ? true : false,
          password_regex_specificsymbol: res['data']['password_regex_specificsymbol'] ? true : false
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await System.getAccountsafety()
      this.loading = false
      if (!res2['code']) {
        this.form2 = {
          password_modify_cycle: res2['data']['password_modify_cycle'],
          password_forcereset_cycle: res2['data']['password_forcereset_cycle'],
          account_lock_unuse: res2['data']['account_lock_unuse']
        }
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
    },
    async submitPasswordverify () {
      let form = {
        password_length_min: this.form['password_length_min'],
        password_length_max: this.form['password_length_max'],
        password_regex_numerial: this.form['password_regex_numerial'] ? 1 : 0,
        password_regex_lowercase: this.form['password_regex_lowercase'] ? 1 : 0,
        password_regex_uppercase: this.form['password_regex_uppercase'] ? 1 : 0,
        password_regex_specificsymbol: this.form['password_regex_specificsymbol'] ? 1 : 0
      }
      this.loading = true
      let res = await System.putPasswordverify(form)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async submitAccountsafety () {
      this.loading = true
      let res = await System.putAccountsafety(this.form2)
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  },
  created () {
    this.getPasswordverify()
  },
  mounted () {
  }
};
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.box {
}
.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  width: 100%;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  width: 100%;
  padding-bottom: 20px;
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

</style>
